.container-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}



.image > a {
    text-align: center;
}

.image > a > img {
    width: 250px !important;
}

body {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

a {
    text-decoration: none;
    color: black;
}

.box-flex {
    display: flex;
    gap: 50px;
    padding-top: 10px;
}

@media only screen and (max-width: 30000) {
    /* For desktop: */
    .image > a {
        text-align: center;
        padding-top: 40px;
    }
    .image > a > img {
        width: 350px !important;
    }
    .box-flex {
        display: flex;
        row-gap: 50px;
    }
    .box-flex {
        display: flex;
        gap: 50px;
    }
}

@media only screen and (max-width: 768px) {
    /* For desktop: */
    .image > a {
        text-align: center;
    }
    .image > a > img {
        width: 200px !important;
    }
    .box-flex {
        display: flex;
        gap: 30px;
    }
}

@media only screen and (max-width: 425px) {
    /* For desktop: */
    .image > a {
        text-align: center;
    }
    .image > a > img {
        width: 100px !important;
    }
    .box-flex {
        display: flex;
        gap: 20px;
    }
}

